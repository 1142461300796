
import { defineComponent, onMounted, ref } from "vue";
import Curso from "@/interfaces/Curso";
import VerCurso from "@/components/aluno/cursos/VerCurso.vue";
import BaseService from "@/services/admin/BaseService";
import store from "@/store";

export default defineComponent({
  components: { VerCurso },
  setup() {
    const loading = ref<boolean>(false);
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const cursos = ref<Curso[]>();
    const modal = ref();

    const openModal = (curso: Curso): void => {
      modal.value.open(curso);
    };

    const getCursos = (): void => {
      loading.value = true;
      BaseService.list("cursos/explorar")
        .then(({ data }) => {
          cursos.value = data.reduce((cursos: any[], item: Curso) => {
              if(store.getters.divisaoId) {
                if (item.divisao.id == store.getters.divisaoId) {
                  cursos.push(item);
                }
              } else {
                cursos.push(item);
              }
              return cursos;
            }, []);
          if (cursos.value?.length == 1) {
            openModal(cursos.value[0]);
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const contarAssuntos = (curso: Curso): string => {
      let count = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach(() => {
          count++;
        });
      });

      if (count != 1) {
        return `${count} assuntos`;
      }
      return `1 assunto`;
    };

    const contarAulas = (curso: Curso): string => {
      let count = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach((assunto) => {
          assunto.aulas.forEach(() => {
            count++;
          });
        });
      });

      if (count != 1) {
        return `${count} aulas`;
      }
      return `1 aula`;
    };

    const porcetangem = (curso: Curso): number[] => {
      let aulas = 0;
      let assistidas = 0;
      let porcentagem = 0;
      let decimal = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach((assunto) => {
          assunto.aulas.forEach((aula) => {
            if (aula.aula_assistida) {
              assistidas++;
            }
            aulas++;
          });
        });
      });
      porcentagem = (assistidas * 100) / aulas;
      decimal = porcentagem / 100;
      return [decimal, porcentagem];
    };

    onMounted(() => {
      getCursos();
    });

    return {
      loading,
      urlImg,
      cursos,
      getCursos,
      contarAulas,
      contarAssuntos,
      porcetangem,
      openModal,
      modal,
    };
  },
});
